import React, { useContext, useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { StoreContext } from '../../../../business/Provider';
import { Dropdown } from 'primereact/dropdown';
import { Input } from '../../../../components/Input/Input';
import { days } from '../../../../utils/utils';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import useSchedules from '../../../../hooks/catalogs/Schedules/useSchedules';
import useReload from '../../../../hooks/useReload';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { TableWorkShift } from './TableWorkShift';
import { useFormik } from 'formik';
import ScheduleCollaboratorObject from '../../../../Dto/Operation/ScheduleCollaboratorObject';
import ScheduleCollaboratorSchema from '../../../../Validations/Operation/ScheduleCollaboratorSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CustomAlertDialog } from '../../../../components/Dialogs/CustomAlertDialog';
import { GeneralNames, OperationNames } from '../../../../namesConstants/names';
import { MassiveCollaboratorsShiftDialog } from './MassiveCollaboratorsShiftDialog';
import moment from 'moment';

export const WorkShiftCollaboratorDialog = ({
	openWorkShifDialog,
	onClose,
	isMassive,
	selectedCollaborators,
	idPersoniaCollaborator,
	setIdPersoniaCollaborator,
	collaboratorWorkShiftDetialId,
	setCollaboratorWorkShiftDetialId,
	workShifts,
	setWorkShifts,
	workShiftSelected,
	setWorkShiftSelected,
	setOpenDialogDeleteSchedule,
	openDialogDeleteSchedule,
	postWorkShiftMassiveCollaboratorsOnOperation,
	postWorkShiftSingleCollaboratorsOnOperation,
	getWorkShiftSingleCollaboratorsOnOperation,
	deleteWorkShiftSingleCollaboratorsOnOperation,
	collaborator,
	setCollaborator,
	viewHistorical,
	setViewHistorical,
}) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [showModal, setShowModal] = useState(false);
	const updateWidth = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', updateWidth);
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []);

	useEffect(() => {
		const _collaborators = selectedCollaborators.map((collaborator) => {
			return collaborator.id;
		});
		formikDataObject.setFieldValue('collaboratorsApply', _collaborators);
	}, [selectedCollaborators]);
	const { getScheduleState, getSchenduleCollaboratorOperationState } =
		useContext(StoreContext);

	const { getSchedules } = useSchedules();
	useReload([getSchedules]);

	const close = () => {
		setWorkShifts([]);
		setViewHistorical(false);
		onClose();
		formikDataObject.resetForm();
		setRestDay([]);
		setIdPersoniaCollaborator(undefined);
		formikDataObject.setFieldValue('tempworkShiftDetail', undefined);
		const _collaborators = selectedCollaborators.map((collaborator) => {
			return collaborator.id;
		});
		formikDataObject.setFieldValue('collaboratorsApply', _collaborators);
		setWorkShiftSelected(undefined);
	};
	useEffect(() => {
		if (openWorkShifDialog) {
			if (
				idPersoniaCollaborator !== undefined &&
				idPersoniaCollaborator !== null
			) {
				getWorkShiftSingleCollaboratorsOnOperation(
					idPersoniaCollaborator,
					viewHistorical
				);
			}
			if (isMassive) {
				setWorkShiftSelected(undefined);
			}
			if (workShiftSelected !== undefined) {
				selectedItem('workShiftDetailId', workShiftSelected);
			}
			getSchedules();
		}
	}, [openWorkShifDialog, viewHistorical]);

	useEffect(() => {
		if (
			getSchenduleCollaboratorOperationState.loading === false &&
			getSchenduleCollaboratorOperationState.schedules !== undefined &&
			getSchenduleCollaboratorOperationState.schedules !== null
		) {
			const _result = getSchenduleCollaboratorOperationState.schedules?.results;
			setWorkShifts(_result);
		}
	}, [getSchenduleCollaboratorOperationState]);

	useEffect(() => {
		if (
			getScheduleState.loading === false &&
			getScheduleState.schedules !== undefined &&
			getScheduleState.schedules !== null
		) {
			const _result = getScheduleState.schedules?.results;
			const _mapResult = _result.map((schedule) => {
				return {
					...schedule,
					codeAndShift: `${schedule.code} ${schedule.starts.substr(
						0,
						5
					)} - ${schedule.ends.substr(0, 5)}`,
				};
			});
			setSchedule(_mapResult);
		}
	}, [getScheduleState]);

	const [collaboratorsList, setCollaboratorsList] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [restDay, setRestDay] = useState([]);
	const formikDataObject = useFormik({
		initialValues: { ...ScheduleCollaboratorObject },
		validationSchema: ScheduleCollaboratorSchema,
	});
	const selectedItem = (name, value) => {
		formikDataObject.setFieldValue(name, value);
		const _filter = schedule.filter((e) => e.workShiftDetailId === value);
		if (_filter.length > 0) {
			const _element = _filter[0];
			formikDataObject.setFieldValue('tempworkShiftDetail', _element);

			const _splitDays = _element.restDays.split(',');
			setRestDay(_splitDays);
		} else {
			setRestDay([]);
		}
	};

	const PostNewScheduleUsers = async () => {
		const dataMassive = {
			collaboratorsApply: formikDataObject.values.collaboratorsApply,
			workShiftDetailId: formikDataObject.values.workShiftDetailId,
			startsOn: formikDataObject.values.startsOn,
			scheduled: formikDataObject.values.scheduled,
		};
		const dataSingle = {
			workShiftDetailId: formikDataObject.values.workShiftDetailId,
			startsOn: formikDataObject.values.startsOn,
			scheduled: formikDataObject.values.scheduled,
		};

		let result = isMassive
			? await postWorkShiftMassiveCollaboratorsOnOperation(dataMassive)
			: await postWorkShiftSingleCollaboratorsOnOperation(
					idPersoniaCollaborator,
					dataSingle
			  );
		if (result?.status === 200) {
			close();
		} else if (result?.status === 207) {
			close();
			setCollaboratorsList(result.data.errorData.errors);
			setShowModal(true);
		} else if (result.status === 409) {
			//close();
		}
	};
	const typeWorkShift = () => {
		return (
			<div className={`flex flex-row w-full space-x-3 h-auto`}>
				<div className='flex flex-col w-1/3'>
					<label
						className='text-small-font-size font-semibold flex'
						htmlFor={'workShiftId'}>
						{'Turno:'}
					</label>
					<Dropdown
						name='workShiftDetailId'
						value={
							formikDataObject.values.workShiftDetailId === '' ||
							formikDataObject.values.workShiftDetailId === undefined
								? workShiftSelected
								: formikDataObject.values.workShiftDetailId
						}
						options={schedule}
						emptyMessage={'No hay datos para mostrar'}
						optionLabel='codeAndShift'
						onChange={(e) => {
							selectedItem(e.target.name, e.value);
						}}
						optionValue='workShiftDetailId'
						className={`!border-r !border-border !w-full !rounded-lg ${
							formikDataObject.touched.workShiftDetailId &&
							Boolean(formikDataObject.errors.workShiftDetailId)
								? 'p-invalid'
								: ''
						}`}
					/>
					{formikDataObject.touched.workShiftDetailId &&
					Boolean(formikDataObject.errors.workShiftDetailId) ? (
						<small id={`error-workshift`} className='p-error block'>
							{'Selecciona un turno'}
						</small>
					) : (
						<></>
					)}
				</div>
				<div className='w-2/3'>
					<Input
						disabled={true}
						name={'Description'}
						value={formikDataObject.values.tempworkShiftDetail?.description}
						placeholder={'Descripción:'}
						classNameContent={'w-full flex-col w-full'}
						className={'w-full !rounded-md !text-font-size-base w-full'}
					/>
				</div>
			</div>
		);
	};
	const daysWorkShift = () => {
		return (
			<div className={`flex flex-row w-full space-x-3 h-auto`}>
				<div className='flex flex-col w-full'>
					<label
						className='text-small-font-size font-semibold flex'
						htmlFor={'selectDayWorkShifts'}>{`Días de descanso:`}</label>
					<SelectButton
						value={restDay}
						disabled
						optionLabel={'name'}
						options={days}
						multiple
						className={`flex !justify-between `}
						id='selectDayWorkShifts'
						name='selectDayWorkShifts'
					/>
				</div>
				<div className='flex flex-col h-full w-full'>{calendarInput()}</div>
				{isMassive ? null : (
					<div className='flex flex-col h-full justify-center w-full'>
						{checkHistorical()}
					</div>
				)}
			</div>
		);
	};

	const calendarInput = () => {
		return (
			<div className=' flex flex-row w-full h-full space-x-3'>
				<div className={`flex  flex-col 'visible'`}>
					<label
						className={`text-small-font-size font-semibold flex`}>{`Fecha de Inicio:`}</label>
					<Calendar
						name='startsOn'
						dateFormat='dd/mm/yy'
						hourFormat='12'
						value={formikDataObject.values.startsOn}
						onChange={(e) => {
							formikDataObject.setFieldValue(e.target.name, e.value);

							// Use Moment.js to handle the date comparison
							let selectedDate = moment(e.value).startOf('day');
							let today = moment().startOf('day').add(1, 'days'); // Día de hoy más uno

							if (selectedDate.isSame(today)) {
								formikDataObject.setFieldValue('scheduled', false);
							} else {
								formikDataObject.setFieldValue('scheduled', true);
							}
						}}
						showIcon
						minDate={
							selectedCollaborators.length === 0
								? workShiftSelected === null
									? moment().toDate()
									: moment().toDate()
								: selectedCollaborators.some(
										(collaborator) => collaborator.shiftDetailId === null
								  )
								? moment().toDate()
								: moment().toDate()
						}
					/>
					{formikDataObject.touched.startsOn &&
					Boolean(formikDataObject.errors.startsOn) ? (
						<small id={`error-workshiftDate`} className='p-error block'>
							{'Selecciona una fecha programada'}
						</small>
					) : (
						<></>
					)}
				</div>
			</div>
		);
	};
	const checkHistorical = () => {
		return (
			<div className='flex flex-row items-center space-x-2 justify-center'>
				<Checkbox
					onChange={(e) => setViewHistorical(e.checked)}
					checked={viewHistorical}></Checkbox>
				<label className='text-small-font-size font-semibold'>
					Incluir anteriores
				</label>
			</div>
		);
	};
	const renderTable = () => {
		return (
			<div className='w-full flex h-full'>
				<TableWorkShift
					workShifts={workShifts}
					setCollaboratorWorkShiftDetialId={setCollaboratorWorkShiftDetialId}
					setOpenDialogDeleteSchedule={setOpenDialogDeleteSchedule}
				/>
			</div>
		);
	};

	const validateForm = async () => {
		const validationErrors = await formikDataObject.validateForm();
		if (
			Object.keys(validationErrors).length > 0 &&
			Object.keys(validationErrors) !== {}
		) {
			Object.keys(validationErrors).forEach((element) => {
				formikDataObject.setFieldTouched(element, true);
				formikDataObject.setFieldError(element, validationErrors[element]);
			});
			return false;
		} else PostNewScheduleUsers();
	};
	const buttoms = () => {
		return (
			<div
				className={`w-full flex flex-row space-x-2 justify-end items-end ${
					isMassive ? 'h-full pb-[10px]' : 'h-auto'
				}`}>
				<Button
					label='Cancelar'
					className='flex !h-4 !w-auto !bg-white text-white rounded-lg !boder !border-[#757575] !text-[#757575] '
					onClick={() => {
						close();
					}}
				/>
				<Button
					label='Guardar'
					className='flex !w-auto  !h-4 !bg-secondary-ShadowPCS002 text-white rounded-lg'
					onClick={() => {
						validateForm();
					}}
				/>
			</div>
		);
	};

	const content = () => {
		return (
			<div className='flex flex-col w-full h-full space-y-2'>
				{typeWorkShift()}
				{daysWorkShift()}
				{!isMassive ? renderTable() : null}
				{buttoms()}
			</div>
		);
	};

	const { loadingState } = useContext(StoreContext);
	const renderLoading = () => {
		return loadingState ? (
			<div className='flex h-full w-full absolute top-0 left-0 right-0 z-50  bg-white opacity-80 justify-center content-center items-center flex-col'>
				<ProgressSpinner mode='indeterminate' className='flex w-full h-8 ' />
			</div>
		) : null;
	};

	const renderAlert = () => {
		return (
			<CustomAlertDialog
				title={OperationNames.CustomAlertDialogTittleDeleteSchedule}
				setOpen={setOpenDialogDeleteSchedule}
				open={openDialogDeleteSchedule}
				message={OperationNames.CustomAlertDialogSuccessMessageDeleteSchedule}
				cancelLabel={GeneralNames.GeneralCancel}
				agreeLabel={GeneralNames.GeneralAccept}
				agreeIcon='pi pi-check'
				onConfirmFunction={() =>
					deleteWorkShiftSingleCollaboratorsOnOperation(
						idPersoniaCollaborator,
						collaboratorWorkShiftDetialId
					)
				}
				onCancelFunction={() => setOpenDialogDeleteSchedule(false)}
				style={{ width: '40vw' }}
			/>
		);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<Dialog
				header={
					<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
						{'Turno ' + (isMassive ? '' : collaborator.fullName)}
					</p>
				}
				visible={openWorkShifDialog}
				modal
				style={{
					width: width < 1000 ? '95vw' : width < 1564 ? ' 75vw' : '50vw',
					height: !isMassive ? '70vh' : '40vh',
					position: 'relative',
				}}
				onHide={() => {
					close();
				}}>
				{renderAlert()}
				{content()}
				{renderLoading()}
			</Dialog>
			{showModal === true ? (
				<MassiveCollaboratorsShiftDialog
					onHide={handleCloseModal}
					filtersList={collaboratorsList}
					showModal={showModal}
				/>
			) : (
				''
			)}
		</>
	);
};
