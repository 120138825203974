import { Dialog } from 'primereact/dialog';
import React from 'react';
import { CustomTable } from '../../../../components/Table/CustomTable';
import {
	GeneralNames,
	OrganizationChartNames,
	TableNames,
} from '../../../../namesConstants/names';
import { ToolbarSearch } from '../../../../components/ToolbarSearch';
import { Column } from 'primereact/column';
import { headerClassName } from '../../../../assets/customStyles';
import { bodyClassName } from '../../../Operation/EmployeeManagement/Const/Constants';
import { Button } from 'primereact/button';
import { PropTypes } from 'prop-types';
import CustomDropdown from '../../../../components/Input/Dropdown';
import { FullLoading } from '../../../../components/FullLoading';
import { Dropdown } from 'primereact/dropdown';
export const DialogOrgChart = ({ service, contextLoading }) => {
	const {
		putLeadersOrganizationChartState,
		getLeadersOrgChartState,
		loadingState,
	} = contextLoading;

	const renderHeader = () => {
		return (
			<div className='flex flex-row w-full  h-full justify-center items-center content-center md:flex-col'>
				<div className='flex flex-col w-full h-full p-1'>
					<Dropdown
						value={service.companyId}
						options={service.companiesData}
						onChange={service.getLeadersByCompanyOnChange}
						optionLabel='name'
						filter
						showClear
						filterBy='name'
						optionValue='instaceGateway'
						placeholder='Seleccione una compañía'
						name='status'
						className='!border-r !border-border  !content-center !items-center !no-uppercase   '
					/>
				</div>

				<ToolbarSearch
					onChange={service.onGlobalFilterChangeDialog}
					placeholder={GeneralNames.GeneralNamesSearchByUser}
					value={service.globalFilterValueDialog}
					clearTextLabel={service.clearTextLabelDialog}
					//iconsTable={service.iconsData}
					hidden
				/>
			</div>
		);
	};
	return (
		<Dialog
			header={
				<p className='text-font-size-base text-[#5e5e5e] font-font-family-base font-bold '>
					{service.isIndividualAssignment
						? 'Asignación de líder para ' +
						  service.individualRowSelected.fullName
						: 'Asignación de líder para los colaboradores seleccionados'}
				</p>
			}
			visible={service.openModal}
			modal
			style={{ width: '80vw', height: '85vh', position: 'relative' }}
			onHide={() => service.setOpenModal(false)}>
			<div className='flex w-full h-full flex-col relative'>
				<FullLoading
					loadingState={
						// putLeadersOrganizationChartState.loading ||
						// getLeadersOrgChartState.loading ||
						loadingState
					}
				/>
				<div className='flex w-full h-full relative'>
					<CustomTable
						//height='h-24'
						value={service.filtersLeadersAndCollaborators(service.leaders)}
						sortField='name'
						header={renderHeader()}
						//loading={loading}
						filters={service.filtersDialog}
						responsiveLayout='scroll'
						globalFilterFields={['code', 'fullName', 'companyName']}
						emptyMessage={GeneralNames.GeneralEmptyMessage}
						currentPageReportTemplate={
							OrganizationChartNames.CurrentPageReportTemplate
						}
						setSort={service.setSort}
						sort={service.sort}
						resizableColumns
						columnResizeMode='fit'
						selectionMode='checkbox'
						style={{ height: '100%', width: '100%' }}
						selection={service.toSendLeaders}
						onSelectionChange={(e) => service.setToSendLeaders(e.value)}>
						<Column
							headerClassName={headerClassName}
							style={{ flexGrow: 1, flexBasis: '30px' }}
							field={'code'}
							header={TableNames.TableCode}
							//sortable={item.sorteable}
							bodyClassName={bodyClassName}
						/>
						<Column
							headerClassName={headerClassName}
							style={{ flexGrow: 1, flexBasis: '100px' }}
							field={'fullName'}
							header={TableNames.TableName}
							//sortable={item.sorteable}
							bodyClassName={bodyClassName}
						/>
						{/* <Column
							headerClassName={headerClassName}
							style={{ flexGrow: 1, flexBasis: '100px' }}
							field={'companyName'}
							header={TableNames.TableCompanyName}
							//sortable={item.sorteable}
							bodyClassName={bodyClassName}
						/> */}
					</CustomTable>
				</div>
				<div className='flex w-full h-16 relative'>
					<div className='flex w-full h-full  flex-row py-1 pl-[4px] pr-[4px] content-center items-center justify-end'>
						<Button
							label={GeneralNames.GeneralCancel}
							className='!flex !w-28 !h-full !bg-white !text-black !text-xs lg:!w-[78px] !mr-3'
							aria-label='cancel'
							onClick={() => service.onCloseModal()}
						/>
						<Button
							label={GeneralNames.GeneralAssign}
							className='!flex !w-28 !h-full !text-xs md:!w-[112px] lg:!w-[112px]'
							aria-label='Submit'
							type='submit'
							onClick={() => service.assignmentLeaders()}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

DialogOrgChart.propTypes = {
	service: PropTypes.object,
	contextLoading: PropTypes.object,
};
