import { InvitationsScreenNames, TableNames } from '../../namesConstants/names';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { RiMailSendLine } from 'react-icons/ri';
import { TbMailOff } from 'react-icons/tb';
import { EmployeeStatusEnum } from './const';
import moment from 'moment';
import { StatusArrayValuesTable } from '../../utils/utils';

export const size = {
	middle: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
	left: `col-span-4  md:col-span-4 lg:col-span-4 2xl:col-span-4`,
	right: `col-span-2  md:col-span-2 lg:col-span-2 2xl:col-span-2`,
};

export function renderValidationButton(rowData) {
	let validation = true;
	if (rowData?.email === null) {
		validation = false;
	} else if (
		rowData?.invitationStatus === 4 ||
		rowData?.invitationStatus === 2 ||
		rowData?.invitationStatus === 1
	) {
		validation = rowData.resend ? true : false;
	} else if (rowData?.additionalInfo !== null && rowData?.resend === false) {
		validation = false;
	} else if (rowData?.additionalInfo !== null) {
		validation = true;
	} else if (rowData?.resend === true && rowData?.additionalInfo !== null) {
		validation = true;
	} else if (rowData?.statusEmployee === 1) {
		validation = false;
	}
	return validation;
}

const informationTemplate = (rowData, service) => {
	return rowData.additionalInfo !== null ? (
		<div
			onClick={() => {
				service.setShowCloseDialog(true);
				service.setDialogContent(rowData.additionalInfo);
			}}
			title={rowData.additionalInfo}
			className='flex w-3 h-3   rounded-full justify-center content-center items-center '>
			<FontAwesomeIcon
				size='sm'
				className='pi text-secondary-PCS002 text-center  '
				icon={faWarning}
			/>
		</div>
	) : null;
};

const actionBodyTemplate = (rowData, service) => {
	return (
		<div className='flex justify-center content-center items-center'>
			{renderValidationButton(rowData) === true ? (
				<div
					onClick={() => service.sendInvitationFunctionAction(rowData)}
					title={InvitationsScreenNames.TextTooltip}
					className='flex w-4 h-4   justify-center content-center items-center cursor-pointer'>
					<RiMailSendLine className='text-black text-2xl' />
				</div>
			) : null}
			{rowData.invitationStatus === 2 || rowData.invitationStatus === 1 ? (
				<div
					onClick={() => service.sendUnInviteFunctionAction(rowData)}
					title={InvitationsScreenNames.TextTooltipUnInvite}
					className='flex w-4 h-4   justify-center content-center items-center cursor-pointer'>
					<TbMailOff className='text-black text-2xl' />
				</div>
			) : null}
		</div>
	);
};

function rowDataColumn(rowData) {
	return rowData;
}

function rowDataEmployeeStatus(rowData) {
	return EmployeeStatusEnum[rowData];
}

function rowDataDateColumn(rowData, field) {
	if (rowData) {
		const formattedDate = moment(rowData[field]).format('DD/MM/YYYY');
		return formattedDate !== 'Invalid date' ? formattedDate : 'Fecha no válida';
	}
	return 'No disponible';
}

function invitationStatusColumn(rowData, rowDataEmail) {
	const filter = StatusArrayValuesTable.filter(
		(item) => item.value === rowData
	)[0]?.label;
	return !rowDataEmail ? 'No disponible' : filter;
}

export function createColumnsTableInvitations(service) {
	return [
		{
			field: 'code',
			header: TableNames.TableCode,
			style: { flexGrow: 1, flexBasis: '50px' },
			body: (rowData) => rowDataColumn(rowData.code),
		},
		{
			field: 'fullName',
			header: TableNames.TableFirstName,
			style: { flexGrow: 4, flexBasis: '100px' },
			body: (rowData) => rowDataColumn(rowData.fullName),
		},
		{
			field: 'email',
			header: TableNames.TableEmail,
			style: { flexGrow: 4, flexBasis: '100px' },
			body: (rowData) => rowDataColumn(rowData.email),
		},
		{
			field: 'statusEmployee',
			header: TableNames.TableStatus,
			style: { flexGrow: 1, flexBasis: '100px' },
			body: (rowData) => rowDataEmployeeStatus(rowData.statusEmployee),
		},
		{
			field: 'invitationSendingDate',
			header: TableNames.TableDateToInvite,
			style: { flexGrow: 3, flexBasis: '100px' },
			dataType: 'date',
			body: (rowData) =>
				rowDataDateColumn(
					rowData.invitationSendingDate,
					'invitationSendingDate'
				),
		},
		{
			field: 'invitationResponseDate',
			header: TableNames.TableDateToResponse,
			style: { flexGrow: 3, flexBasis: '100px' },
			dataType: 'date',
			body: (rowData) =>
				rowDataDateColumn(
					rowData.invitationResponseDate,
					'invitationResponseDate'
				),
		},
		{
			field: 'invitationStatus',
			header: TableNames.TableStatusInvitation,
			style: { flexGrow: 3, flexBasis: '100px' },
			body: (rowData) =>
				invitationStatusColumn(rowData.invitationStatus, rowData.email),
		},
		{
			field: 'additionalInfo',
			header: 'Información',
			style: { flexGrow: 1, flexBasis: '100px' },
			body: (rowData) => informationTemplate(rowData, service),
		},
		{
			field: null,
			header: TableNames.TableActions,
			style: { flexGrow: 1, flexBasis: '100px' },
			body: (rowData) => actionBodyTemplate(rowData, service),
		},
	];
}
