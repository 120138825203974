import { createContext, useContext, useEffect, useState } from 'react';
import {
	initialStateTable,
	initialStateTableDialog,
} from '../../screens/Invitations/const';
import { useOrganizationChart } from '../../hooks/organizationChart/useOrganizationChart';
import { StoreContext } from '../../business/Provider';
import { OrganizationChartNames } from '../../namesConstants/names';
import { Toast } from '../../components/toast';
import { toExcelExportOrgChart } from '../../screens/OrganizationChart/components/helpers';
import useReload from '../../hooks/useReload';

export const OrganizationChartContext = createContext();

export const OrganizationChartServiceManagerProvider = ({ children }) => {
	const {
		getOrgChartContextState,
		getFiltersOrganizationChartState,
		getOrganizationChartState,
		getLeadersOrgChartState,
		dispatchLoading,
		companyState,
	} = useContext(StoreContext);

	const { stateGetOrgChart } = getOrgChartContextState;

	const {
		dataFilterOrganizationChart,
		getOrganizationChartData,
		getLeadersOrganizationChartData,
		putLeadersAssignment,
	} = useOrganizationChart();

	const [dataTable, setDataTable] = useState([]);
	const [filters, setFilters] = useState(initialStateTable);
	const [filtersDialog, setFiltersDialog] = useState(initialStateTableDialog);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [globalFilterValueDialog, setGlobalFilterValueDialog] = useState('');
	const [invitedCollaborator, setInvitedCollaborator] = useState({
		key: '1',
		name: OrganizationChartNames.AllCollaborators,
		value: false,
	});
	const [sort, setSort] = useState([{ field: 'fullName', order: 1 }]);
	const [typePeriodSelected, setTypePeriodSelected] = useState([]);
	const [periodsTypesData, setPeriodsTypesData] = useState([]);
	const [departmentsSelected, setDepartmentsSelected] = useState([]);
	const [departmentsData, setDepartmentsData] = useState([]);
	const [positionsSelected, setPositionsSelected] = useState([]);
	const [positionsData, setPositionsData] = useState([]);
	const [shiftSelected, setShiftSelected] = useState([]);
	const [shiftData, setShiftData] = useState([]);
	const [workCenterSelected, setWorkCenterSelected] = useState([]);
	const [workCenterData, setWorkCenterData] = useState([]);
	const [actualLeaderSelected, setActualLeaderSelected] = useState([]);
	const [actualLeaderData, setActualLeaderData] = useState([]);
	const [collaboratorSelected, setCollaboratorSelected] = useState([]);
	const [collaboratorData, setCollaboratorData] = useState([]);
	const [individualRowSelected, setIndividualRowSelected] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [isIndividualAssignment, setIsIndividualAssignment] = useState(false);
	const [leaders, setLeaders] = useState([]);
	const [toSend, setToSend] = useState([]);
	const [toSendLeaders, setToSendLeaders] = useState([]);
	const [companiesData, setCompaniesData] = useState([]);
	// const [storageByCompany, setStorageByCompany] = useState([]);
	const [companyId, setCompanyId] = useState(null);

	useEffect(() => {
		if (
			stateGetOrgChart.data !== undefined &&
			stateGetOrgChart.loading === false
		) {
			const { data } = stateGetOrgChart;

			setPeriodsTypesData(data.periodType);
			setDepartmentsData(data.department);
			setPositionsData(data.position);
			setShiftData(data.shift);
			setWorkCenterData(data.workCenter);
			setActualLeaderData(data.actualLeader);
			setCollaboratorData(data.employee);
			let newCompanies = data.companies.map((c) => {
				const alias = c.alias !== '' ? ` (${c.alias})` : '';
				return {
					...c,
					name: `${c.name} ${alias}`,
				};
			});
			setCompaniesData(newCompanies);

			if (getOrganizationChartState.isLoad === false)
				getCollaboratorsOrgChart();
		}
	}, [stateGetOrgChart, getOrganizationChartState.isLoad]);

	useReload([getCollaboratorsOrgChart, dataFilterOrganizationChart]);

	useEffect(() => {
		if (
			getLeadersOrgChartState.data !== undefined &&
			getLeadersOrgChartState.loading === false
		) {
			// setStorageByCompany([
			// 	...storageByCompany,
			// 	{ companyId: companyId, data: getLeadersOrgChartState.data },
			// ]);
			setLeaders(getLeadersOrgChartState.data);
		}
	}, [getLeadersOrgChartState]);

	useEffect(() => {
		if (
			getOrganizationChartState.data !== null &&
			getOrganizationChartState.loading === false
		) {
			const { data } = getOrganizationChartState;

			setDataTable(data);
		}
	}, [getOrganizationChartState]);

	function onHandleTypePeriods(data) {
		setTypePeriodSelected(data.value);
	}
	function onHandleDepartments(data) {
		setDepartmentsSelected(data.value);
	}
	function onHandlePositions(data) {
		setPositionsSelected(data.value);
	}
	function onHandleShifts(data) {
		setShiftSelected(data.value);
	}
	function onHandleWorkCenters(data) {
		setWorkCenterSelected(data.value);
	}
	function onHandleActualLeader(data) {
		setActualLeaderSelected(data.value);
	}
	function onHandleCollaborators(data) {
		setCollaboratorSelected(data.value);
	}

	// function getLeadersByStorage(companyId) {
	// 	// const leaders = storageByCompany.find(
	// 	// 	(company) => company.companyId === companyId
	// 	// );
	// 	// setLeaders(leaders.data);
	// }

	async function getLeadersByCompanyOnChange(e) {
		// ;
		// const findCompany = storageByCompany.find(
		// 	(company) => company.companyId === companyId
		// );
		// ;
		// return findCompany === undefined
		// 	? await getLeadersOrganizationChartData(e.target.value)
		// 	: getLeadersByStorage(companyId);
		const _companyId = e.target.value;
		setCompanyId(_companyId);
		return await getLeadersOrganizationChartData(e.target.value);
	}

	function filtersLeadersAndCollaborators(leaders) {
		const itemsFilter = toSend.length > 0 ? toSend : [individualRowSelected];

		const filterData =
			toSend.length > 0 && individualRowSelected !== null
				? leaders.filter(
						(lead) => !itemsFilter.some((item) => item.employeeId === lead.id)
				  )
				: leaders;
		return filterData;
	}

	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setFilters(_filters);
		setGlobalFilterValue(newValue);
	};
	const onGlobalFilterChangeDialog = (e) => {
		const { value } = e.target;
		const newValue = value.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filtersDialog };
		_filters.global.value = newValue;
		setFiltersDialog(_filters);
		setGlobalFilterValueDialog(newValue);
	};

	const clearTextLabel = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filters };
		_filters.global.value = newValue;
		setGlobalFilterValue(newValue);
		setFilters(_filters);
	};
	const clearTextLabelDialog = () => {
		const newValue = ''.replace(
			'^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$',
			' '
		);
		const _filters = { ...filtersDialog };
		_filters.global.value = newValue;
		setGlobalFilterValueDialog(newValue);
		setFiltersDialog(_filters);
	};

	const iconsData = [
		{
			icon: 'userCi',
			title: 'Asignación masiva de líderes.',
			onClick: () => {
				if (toSend.length > 0) {
					setOpenModal(true);
					setIsIndividualAssignment(false);
				} else {
					Toast('warning', 'Debe seleccionar al menos un colaborador');
				}
			},
		},
		// {
		// 	icon: 'cloudUpload',
		// 	title: 'Importar masivamente',
		// 	onClick: () => {
		// 		console.log();
		// 	},
		// },
		{
			icon: 'excel',
			title: 'Exportar excel',
			onClick: toExcelExportFunction,
		},
	];

	async function toExcelExportFunction() {
		return await toExcelExportOrgChart(
			dataTable,
			dispatchLoading,
			companyState,
			sort
		);
	}

	async function handleInvitedCollaborator(item) {
		setInvitedCollaborator(item);
		//return dataFilterOrganizationChart(item.value);
	}

	function mapToId(_data, property = 'id') {
		return _data.length !== 0 ? _data.map((item) => item[property]) : [];
	}

	function createDataBase64() {
		const data = {
			periodType: mapToId(typePeriodSelected),
			department: mapToId(departmentsSelected),
			position: mapToId(positionsSelected),
			shift: mapToId(shiftSelected),
			workCenter: mapToId(workCenterSelected),
			actualLeader: mapToId(actualLeaderSelected),
			employee: mapToId(collaboratorSelected),
			onlyInvited: invitedCollaborator?.value || false,
		};
		return Buffer.from(JSON.stringify(data)).toString('base64');
	}

	async function getCollaboratorsOrgChart() {
		const dataToBase64 = createDataBase64();
		return getOrganizationChartData(dataToBase64);
	}

	function clearFilters() {
		setTypePeriodSelected([]);
		setDepartmentsSelected([]);
		setPositionsSelected([]);
		setShiftSelected([]);
		setWorkCenterSelected([]);
		setActualLeaderSelected([]);
		setCollaboratorSelected([]);
		setInvitedCollaborator({
			key: '1',
			name: OrganizationChartNames.AllCollaborators,
			value: false,
		});
	}

	function onCloseModal() {
		setOpenModal(false);
		setIsIndividualAssignment(false);
		setToSendLeaders([]);
		setToSend([]);
		setIndividualRowSelected(null);
		setCompanyId('');
		setLeaders([]);
	}

	async function assignmentLeaders() {
		if (toSendLeaders.length > 0) {
			const leaderSelect = toSendLeaders[0];

			const _dataMap = isIndividualAssignment
				? [individualRowSelected]
				: toSend;

			const newData = _dataMap.map((item) => {
				return {
					employeeId: item.employeeId,
					leaderEmployeeId: leaderSelect.id, //el del lider
					LeaderExternalInstanceId: leaderSelect.externalId, //colabora instance de la compania seleccioanda
					LeaderInstanceId: leaderSelect.colaboraInstanceId,
				};
			});

			const dataToBase64 = createDataBase64();

			return await putLeadersAssignment(newData, dataToBase64).finally(() => {
				onCloseModal();
			});
		} else {
			Toast('warning', 'Debe seleccionar al menos algún líder');
		}
	}

	return (
		<OrganizationChartContext.Provider
			value={{
				onGlobalFilterChange,
				globalFilterValue,
				clearTextLabel,
				iconsData,
				dataTable,
				filters,
				sort,
				setSort,
				invitedCollaborator,
				handleInvitedCollaborator,
				typePeriodSelected,
				setTypePeriodSelected,
				onHandleTypePeriods,
				departmentsSelected,
				setDepartmentsSelected,
				onHandleDepartments,
				positionsSelected,
				setPositionsSelected,
				onHandlePositions,
				shiftSelected,
				setShiftSelected,
				onHandleShifts,
				workCenterSelected,
				setWorkCenterSelected,
				onHandleWorkCenters,
				actualLeaderSelected,
				setActualLeaderSelected,
				onHandleActualLeader,
				collaboratorSelected,
				setCollaboratorSelected,
				onHandleCollaborators,
				periodsTypesData,
				departmentsData,
				positionsData,
				shiftData,
				workCenterData,
				actualLeaderData,
				collaboratorData,
				getCollaboratorsOrgChart,
				clearFilters,
				setIndividualRowSelected,
				setIsIndividualAssignment,
				setOpenModal,
				leaders,
				toSend,
				setToSend,
				toSendLeaders,
				setToSendLeaders,
				isIndividualAssignment,
				individualRowSelected,
				openModal,
				onCloseModal,
				filtersDialog,
				clearTextLabelDialog,
				onGlobalFilterChangeDialog,
				assignmentLeaders,
				globalFilterValueDialog,
				companiesData,
				getLeadersByCompanyOnChange,
				companyId,
				filtersLeadersAndCollaborators,
			}}>
			{children}
		</OrganizationChartContext.Provider>
	);
};
